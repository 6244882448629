/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import error_404 from "../../assets/lottie/error_404.json";
import error_500 from "../../assets/lottie/error_500.json";
import laptop from "../../assets/lottie/laptop.json";
import not_found from "../../assets/lottie/not_found.json";
import loader from "../../assets/lottie/loader.json";

export const DataLottie = {
    error_500: error_500,
    error_404: error_404,
    laptop: laptop,
    not_found: not_found,
    loader: loader,
};
