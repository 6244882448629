/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const Helper = {
    /**
     * Find error array validate
     */
    findError: function (field, stateResponse) {
        if (!stateResponse.validates) {
            return null
        }
        return stateResponse.validates
            .find(el => el['filed'] === field)
            ?.errors.map((e) => e.charAt(0).toUpperCase() + e.slice(1) + '.')
            ?.join(' ')
            ?? null
    },
};
