/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';
import { useParams } from 'react-router';
import { FormLayout } from '../../layouts';
import { useHttpQuery, Shared } from '../../base';
import { CityForm } from './elements/CityForm';

export function CityEditPage(props) {
    let {id} = useParams();
    const countries = useHttpQuery(Shared.queries.countries)
    const organizers = useHttpQuery(Shared.queries.organizers)
    const model = useHttpQuery(Shared.queries.city, id)

    return (
        <FormLayout
            id={id}
            model={model}
            loading={countries === undefined || organizers === undefined}
            title={'Edit city'}
        >
            <CityForm
                id={id}
                model={model}
                countries={countries?.toArray() ?? []}
                organizers={organizers?.toArray() ?? []}
            />
        </FormLayout>
    )
}

CityEditPage.propTypes = {};
